<template>
  <user-crud />
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";

import UserCrud from "../../components/users/UserCrud";
import { FETCHING_ACCOUNT } from "../../store/modules/account/account.actions.type";
import { GET_USERS } from "../../store/modules/user/user.getters.type";

export default {
  name: "Users",
  components: {
    UserCrud,
  },
  setup() {
    const store = useStore();
    onMounted(async () => {
      if (store.getters[GET_USERS].length === 0) {
        await store.dispatch(FETCHING_ACCOUNT);
      }
    });
  },
};
</script>
