<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <Toolbar class="p-mb-4">
        <template #start>
          <h5 class="p-m-0">
            {{ $t("users.table_title") }}
          </h5>
        </template>

        <template #end>
          <Button
            :label="translation.newUser"
            icon="pi pi-plus"
            class="p-button-secondary p-mr-2 p-mb*2"
            data-testid="register-user"
            @click="onPressAddModal"
          />
        </template>
      </Toolbar>

      <DataTable
        :value="users"
        :resizable-columns="true"
        column-resize-mode="expand"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[5, 10, 25]"
      >
        <Column field="name" :header="translation.name" :sortable="true" />
        <Column
          field="surname"
          :header="translation.surname"
          :sortable="true"
        />
        <Column field="email" :header="translation.email" :sortable="true" />
        <Column style="text-align: right">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success p-mr-2"
              :data-testid="'update-' + slotProps.data.id"
              @click="onPressUpdateModal(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger"
              :data-testid="'delete-' + slotProps.data.id"
              @click="onPressDeleteModal(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>

      <Dialog
        v-model:visible="showDialog"
        :style="{ width: '450px' }"
        :header="translation.headerUser"
        :modal="true"
        class="p-fluid p-pb-0"
      >
        <div class="p-field">
          <label for="first-name">{{ $t("users.modal_add.field_name") }}</label>
          <InputText
            id="first-name"
            v-model="user.name"
            aria-label="first-name"
            aria-describedby="first-name-error"
            :class="{ 'p-invalid': !!errors['name'] }"
          />
          <small
            v-if="!!errors['name']"
            id="first-name-error"
            class="p-error"
            role="alert"
            aria-live="polite"
            >{{ $t("users.modal_add.field_name_error") }}</small
          >
        </div>
        <div class="p-field">
          <label for="surname">{{ $t("users.modal_add.field_surname") }}</label>
          <InputText
            id="surname"
            v-model="user.surname"
            aria-label="surname"
            aria-describedby="surname-error"
            :class="{ 'p-invalid': !!errors['surname'] }"
          />
          <small
            v-if="!!errors['surname']"
            id="surname-error"
            class="p-error"
            role="alert"
            aria-live="polite"
            >{{ $t("users.modal_add.field_surname_error") }}</small
          >
        </div>
        <div class="p-field">
          <label for="email">{{ $t("users.modal_add.field_email") }}</label>
          <InputText
            id="email"
            v-model.trim="user.email"
            aria-label="email"
            aria-describedby="email-error"
            :class="{ 'p-invalid': !!errors['email'] }"
          />
          <small
            v-if="!!errors['email']"
            id="email-error"
            class="p-error"
            role="alert"
            aria-live="polite"
            >{{ $t("users.modal_add.field_email_error") }}</small
          >
        </div>
        <!--
          <div class="p-field">
            <label for="type">Type</label>
            <div class="p-grid p-mt-1 p-ml-2">
              <div class="p-col-12 p-md-4">
                <div class="p-field-radiobutton">
                  <RadioButton
                    id="standard"
                    v-model="user.isAdmin"
                    name="type"
                    value="false"
                  />
                  <label for="option1">Standard</label>
                </div>
              </div>
              <div class="p-col-12 p-md-4">
                <div class="p-field-radiobutton">
                  <RadioButton
                    id="administrator"
                    v-model="user.isAdmin"
                    name="type"
                    value="true"
                  />
                  <label for="option2">Administrator</label>
                </div>
              </div>
            </div>
          </div>
          -->
        <template #footer>
          <Button
            :label="translation.buttonCancel"
            type="submit"
            icon="pi pi-times"
            class="p-button-text"
            data-testid="buttonCancelRegisterOrUpdate"
            @click="showDialog = false"
          />
          <Button
            :label="translation.buttonSave"
            icon="pi pi-check"
            data-testid="buttonSaveRegisterOrUpdate"
            @click="onSave"
          />
        </template>
      </Dialog>

      <Dialog
        v-model:visible="showDeleteDialog"
        :style="{ width: '450px' }"
        :header="translation.headerConfirm"
        :modal="true"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span>{{
            $t("users.modal_delete.body", {
              name: user.name,
              surname: user.surname,
            })
          }}</span>
        </div>

        <template #footer>
          <Button
            :label="translation.buttonCancel"
            icon="pi pi-times"
            class="p-button-text"
            data-testid="buttonCancelDelete"
            @click="showDeleteDialog = false"
          />
          <Button
            :label="translation.buttonDelete"
            icon="pi pi-check"
            class="p-button-danger"
            autofocus
            data-testid="buttonDeleteUser"
            @click="onPressDelete"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Toolbar from "primevue/toolbar";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../../config";
import User from "../../models/User";
import { assertEmail, assertEmpty } from "../../service/assert";
import {
  REGISTER_USER,
  UNREGISTER_USER,
  UPDATE_USER,
} from "../../store/modules/user/user.actions.type";
import { GET_USERS } from "../../store/modules/user/user.getters.type";

export default {
  name: "UserCrud",
  components: {
    Button,
    Column,
    DataTable,
    Dialog,
    InputText,
    Toolbar,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const users = computed(() => store.getters[GET_USERS]);
    const user = ref(User.empty());
    const errors = ref([]);
    const showDialog = ref(false);
    const showDeleteDialog = ref(false);
    let isUpdate = false;
    const { t } = useI18n();
    const translation = {
      name: t("users.table_column_name"),
      surname: t("users.table_column_surname"),
      email: t("users.table_column_email"),
      newUser: t("users.button_add"),
      headerConfirm: t("users.modal_delete.header"),
      headerUser: t("users.modal_add.header"),
      buttonDelete: t("common.delete"),
      buttonSave: t("common.save"),
      buttonCancel: t("common.cancel"),
    };

    function onPressAddModal() {
      user.value = User.empty();
      showDialog.value = true;
      isUpdate = false;
    }

    function onPressUpdateModal(data) {
      user.value = { ...data };
      showDialog.value = true;
      isUpdate = true;
    }

    const onSave = async () => {
      if (validateInputs()) {
        if (isUpdate) {
          await updateUser();
        } else {
          await addUser();
        }
        showDialog.value = false;
      }
    };

    async function addUser() {
      await store.dispatch(REGISTER_USER, user.value);
      message(t("users.modal_add.toast_success"));
    }

    async function updateUser() {
      await store.dispatch(UPDATE_USER, user.value);
      message(t("users.modal_update.toast_success"));
    }

    function validateInputs() {
      errors.value = [];
      if (assertEmpty(user.value.name)) {
        errors.value["name"] = true;
      }
      if (assertEmpty(user.value.surname)) {
        errors.value["surname"] = true;
      }
      if (!assertEmail(user.value.email)) {
        errors.value["email"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }

    function onPressDeleteModal(data) {
      user.value = data;
      showDeleteDialog.value = true;
    }

    async function onPressDelete() {
      await store.dispatch(UNREGISTER_USER, user.value);
      showDeleteDialog.value = false;
      message(t("users.modal_delete.toast_success"));
    }

    const message = (message) => {
      toast.add({
        severity: "success",
        summary: t("common.success"),
        detail: message,
        life: TOAST_LIFE,
      });
    };

    return {
      onPressAddModal,
      showDialog,
      onSave,
      onPressUpdateModal,
      onPressDeleteModal,
      showDeleteDialog,
      onPressDelete,
      translation,
      errors,
      users,
      user,
    };
  },
};
</script>
